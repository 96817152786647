const defaultErrorMessage = 'unknown graphql error'

export function getGraphQLErrorMessage(errors?: any[]) {
  let message = ''
  if (errors && Array.isArray(errors)) {
    message = errors.map((err) => err.message).join('|')
  }
  return message || defaultErrorMessage
}

export function getGraphQLErrorCode(graphQLErrors?: any[]) {
  if (graphQLErrors && Array.isArray(graphQLErrors) && graphQLErrors.length) {
    return graphQLErrors[0].extensions?.exception?.code
  } else {
    return null
  }
}
