import { watch, ref, onMounted, onUnmounted } from '@nuxtjs/composition-api'
// default max ripple size
const MAX_SIZE = 300

// px helper
const px = (n) => n.toString() + 'px'

// offsetX polyfill
const getOffset = (event, container) => {
  if (!event.target) {
    return event
  }
  let target = event.target

  // direct click on the target
  const targetOk = target.isSameNode(container)
  if (event.offsetX !== undefined && targetOk) {
    return { x: event.offsetX, y: event.offsetY }
  }

  // need to calculate the offset of the target
  const offset = { x: 0, y: 0 }
  while (!target.isSameNode(container) && target.offsetParent) {
    offset.x += target.offsetLeft
    offset.y += target.offsetTop
    target = target.offsetParent
  }

  offset.x = event.offsetX + offset.x
  offset.y = event.offsetY + offset.y

  return offset
}

const calcRadius = (c, maxSize) => {
  return (Math.min(Math.max(c.offsetHeight, c.offsetWidth), maxSize) - 2) * 0.85
}

class FiRipple {
  constructor(el, { color } = {}) {
    // default settings
    this.container = el
    // prepare ripple container
    this.updateContainer(color)
    el.addEventListener('mousedown', this.spawnRipple.bind(this), { passive: true })
  }

  spawnRipple(event, container = this.container) {
    const { x, y } = getOffset(event, container)
    const radius = calcRadius(container, event.maxSize || MAX_SIZE)

    // create the ripple & prepare it
    const ripple = document.createElement('div')
    ripple.className = 'p-ripple'

    // calc the ripple size
    Object.assign(ripple.style, {
      transform: 'scale(0) translateZ(0)',
      width: px(radius * 2),
      height: px(radius * 2),
      left: px(x - radius),
      top: px(y - radius),
      backgroundColor: event.color || this.backgroundColor,
      '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
    })

    // Append child when the browser expects it
    window.requestAnimationFrame(() => {
      container.appendChild(ripple)

      // wait some time, so that the transition actually triggers
      window.requestAnimationFrame(() => {
        ripple.style.transform = 'scale(1) translateZ(0)'
      })
    })

    // programatic trigger
    if (event instanceof MouseEvent !== true) {
      if (event.persistent !== true) {
        this.removeRipple(ripple, container)
      }
      return ripple
    }

    // the next mouseup event should remove the ripple again
    window.addEventListener('mouseup', this.removeRipple.bind(this, ripple, container), {
      passive: true,
      once: true,
    })

    return ripple
  }

  removeRipple(ripple, container = this.container) {
    if (!ripple) {
      return
    }

    // fade out ripple after some time
    setTimeout(() => (ripple.style.opacity = 0), 150)

    // remove ripple after transition is done
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        container.removeChild(ripple)
      })
    }, 500)
  }

  updateContainer(color) {
    this.backgroundColor = color
    if (!color) {
      setTimeout(() => {
        this.backgroundColor = window.getComputedStyle(this.container).color
      }, 0)
    }
  }

  destroy() {
    this.container.removeEventListener('mousedown', this.spawnRipple)
  }
}

export function useRipple (elRef, color) {
  const instance = ref(null)

  watch(
    () => elRef.value,
    (el) => {
      if (el && !instance.value) {
        el._fiRipple = new FiRipple(el, { color })
        el._fiRipple.updateContainer(color)
        instance.value = el
      } else if (!el && instance.value) {
        instance.value._fiRipple.destroy()
      }
    },
    { immediate: true },
  )

  onUnmounted(() => {
    if (instance.value) {
      instance.value._fiRipple.destroy()
      // instance.value._fiRipple.updateContainer(binding.value)
    }
  })
}

// Vue.directive('ripple', {
//   bind(el, binding, vnode) {
//     if (!binding.hasOwnProperty('value') || binding.value) {
//       el._fiRipple = new FiRipple(el, { color: binding.value })
//     }
//   },
//   update(el, binding) {
//     el._fiRipple && el._fiRipple.updateContainer(binding.value)
//   },
//   unbind(el) {
//     el._fiRipple && el._fiRipple.destroy()
//   },
// })
