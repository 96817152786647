import { useStore, computed, nextTick, useRouter, ref } from '@nuxtjs/composition-api'
import { useMessage } from '~/use/useMessage'
import { RegisterInput, MagicLinkInput } from '~/services/api/db'
import { register, me, magicLink } from '~/services/api/db-user'

export type ErrorMessageCode = {
  message: string
  code: string
}

export const USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS'

export function useAuth() {
  const { showError } = useMessage()
  const router = useRouter()
  const store = useStore() as any
  const token = computed(() => store.getters.token)
  const email = computed(() => store.getters['user/email'])
  const isFitted = computed(() => store.getters['user/isFitted'])
  const needsRefetchUser = computed(() => token.value && !email.value)

  function clearState () {
    store.commit('CLEAR_STATE')
    store.commit('user/CLEAR_STATE')
    store.commit('cart/CLEAR_STATE')
  }

  async function navigationGuard (onDone = () => {}) {
    try {
      if (!token.value) {
        router.push('/')
      } else if (!isFitted.value) {
        await fetchUser() // refresh user
      }

      onDone()
    } catch (err) {
      console.log(err)
      showError({ err, notify: true })
    }
  }

  async function registerUser(data: RegisterInput) {
    const { user, token } = await register(data)
    store.commit('SET_TOKEN', token)
    store.commit('user/SET_USERDATA', user)
  }

  async function requestMagicLink(data: MagicLinkInput) {
    await magicLink(data)
  }

  async function loginUser(token: string) {
    clearState()
    const user = await me(token)
    store.commit('SET_TOKEN', token)
    store.commit('user/SET_USERDATA', user)
  }

  async function fetchUser() {
    const user = await me(token.value)
    store.commit('user/SET_USERDATA', user)
  }

  function logoutUser() {
    clearState()
    router.push('/')
  }

  return {
    navigationGuard,
    registerUser,
    loginUser,
    logoutUser,
    requestMagicLink,
    fetchUser,
    needsRefetchUser,
  }
}
