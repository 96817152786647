import { ref, toRefs, useRouter } from '@nuxtjs/composition-api'

export function useBtn(props: any, { emit }: any) {
  const router = useRouter()

  const { to } = toRefs(props)

  const component = ref(to && to.value ? 'a' : 'button')

  const href: any = ref(null)

  if (to && to.value) {
    href.value = router.resolve(to.value).href
  }

  async function onClick(e: MouseEvent) {
    if (to && to.value) {
      e.preventDefault()
      e.stopImmediatePropagation()
      await router.push(to.value)

      emit('click', e)
    } else {
      emit('click', e)
    }
    return false
  }

  function isTruthy(val: any) {
    return typeof val !== 'undefined' && val !== false && val !== 'false'
  }

  return {
    component,
    onClick,
    isTruthy,
  }
}
