import {
  Ref,
  onUnmounted,
  watch,
  isRef,
  reactive,
  toRefs,
  getCurrentInstance,
  onMounted,
} from '@nuxtjs/composition-api'
import _merge from 'lodash.merge'

function buildThresholdList(numSteps = 20) {
  const thresholds = []

  for (let i = 1.0; i <= numSteps; i++) {
    const ratio = i / numSteps
    thresholds.push(ratio)
  }

  thresholds.push(0)
  return thresholds
}

const defaultProps = {
  threshold: buildThresholdList(4),
}

function createObserver(
  element: Element,
  observerProps: IntersectionObserverInit = {},
  observeResult: IObserveResult,
  handleIntersect?: (p: boolean) => void,
) {
  observeResult.observer = new IntersectionObserver(
    (entries) => {
      let isIntersecting = false
      const intersectionRatio = entries[0].intersectionRatio
      for (let i = 0; i < entries.length; i++) {
        if (entries[i].isIntersecting) {
          isIntersecting = true
          break
        }
      }
      if (entries.length > 1) {
      }
      if (observeResult.isIntersecting !== isIntersecting) {
        handleIntersect && handleIntersect(isIntersecting)
        observeResult.isIntersecting = isIntersecting
      }

      if (observeResult.intersectionRatio !== intersectionRatio) {
        observeResult.intersectionRatio = intersectionRatio
      }
    },
    { ...defaultProps, ...observerProps },
  )
  observeResult.observer.observe(element)

  return observeResult
}

function disconnect(observers: IntersectionObserver[], observer?: IntersectionObserver) {
  if (!observer) {
    return
  }
  const index = observers.indexOf(observer)
  if (index > -1) {
    observers.splice(index, 1)
  }
  observer.disconnect()
}

const initialObserveResult = {
  isIntersecting: false,
  intersectionRatio: 0,
  observer: undefined as IntersectionObserver | undefined,
  // entries: undefined as IntersectionObserverEntry | undefined,
}

const initialObserveOptions = {
  once: false,
  handler: undefined as ((v: boolean) => void) | undefined,
}

export type IObserveResult = typeof initialObserveResult
export type IObserveOptions = typeof initialObserveOptions

const elements: any[] = []
export function useIntersectionObserver() {
  const observers: IntersectionObserver[] = []

  const observe = (
    element: (Element | string | null) | Ref<Element | null>,
    options: Partial<IObserveOptions & IntersectionObserverInit> = {},
  ) => {
    elements.push(element)
    const { once, handler, ...observerProps } = _merge({ ...initialObserveOptions }, options)
    const observeResult = reactive({ ...initialObserveResult })

    function setupObserver(element: Element | string | null) {
      element = typeof element === 'string' ? document.querySelector(element) : element
      if (!element) {
        return
      }
      if (observeResult.observer) {
        disconnect(observers, observeResult.observer)
      }

      let handleIntersect
      if (handler) {
        handleIntersect = (value: boolean) => {
          handler(value)
          // disconnect observers if need to be triggered once
          if (once && value === true) {
            disconnect(observers, observeResult.observer)
          }
        }
      }

      createObserver(element, observerProps, observeResult, handleIntersect)

      observeResult.observer && observers.push(observeResult.observer)
    }

    onMounted(() => {
      if (element instanceof HTMLElement || typeof element === 'string') {
        setupObserver(element)
      } else if (isRef(element)) {
        watch(
          () => element.value,
          (el) => el && setupObserver(el),
          { immediate: true },
        )
      }
    })

    return toRefs(observeResult)
  }

  // hide warning in console caused by trying destroy when running without component
  !!getCurrentInstance() &&
    onUnmounted(() => {
      if (observers.length) {
        observers.forEach((observer) => disconnect(observers, observer))
      }
    })

  const classListAddIntersecting = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver,
    intersecting: boolean,
  ) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const target = entries[0].target
    if (intersecting && !target.classList.contains('intersecting')) {
      target.classList.add('intersecting')
    }
  }

  const classListAdd = (className: string) => {
    return function (entries: IntersectionObserverEntry[], observer: IntersectionObserver, intersecting: boolean) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      const target = entries[0].target
      if (intersecting) {
        target.classList.add(...className.split(' '))
      }
    }
  }

  const animateChildren = ({ delayStep = 0, initialDelay = 0 }) => {
    return function (entries: IntersectionObserverEntry[], observer: IntersectionObserver, intersecting: boolean) {
      if (intersecting) {
        const elements = entries[0].target.querySelectorAll('[class*="--initial"]')
        setTimeout(() => {
          elements.forEach((el, index) => {
            if (delayStep) {
              el.classList.add(`animation-delay-${index * delayStep}x`)
            }
            const match = el.className.match(/([^\s]+)--initial/)
            if (match) {
              const className = match[1]
              el.classList.add(...['animated', className])
            }
          })
        }, initialDelay)
      }
    }
  }

  const defaultIntersectOptions = {
    // threshold: [1.0],
    // trackVisibility: true,
    // delay: 300,
  }

  return {
    observe,
    classListAdd,
    classListAddIntersecting,
    animateChildren,
    defaultIntersectOptions,
  }
}
