import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store'
import { Measurement } from '~/services/api/db'

function getInitialState() {
  return {
    email: '',
    firstName: '',
    lastName: '',
    measurement: {
      measurementData: null as any,
      fit: '',
      size: 0,
      fitPreference: '' as any,
      lengthPreference: '' as any,
    } as Measurement,
  }
}

export const state = () => getInitialState()

export type UserModuleState = ReturnType<typeof state>

export const getters: GetterTree<UserModuleState, RootState> = {
  isFitted(state) {
    const { fit, size } = state.measurement || {}
    const isFitted = !!(fit && size)

    return isFitted
  },
  measurementInfo(state) {
    const { measurementData } = state.measurement || {}

    if (measurementData) {
      const { height, weight, volume_params, front_params } = measurementData
      const { chest, waist, high_hips, low_hips } = volume_params
      const { torso_height: torso } = front_params

      return {
        height,
        weight,
        chest,
        waist,
        high_hips,
        low_hips,
        torso,
      }
    } else {
      return {}
    }
  },

  fit(state) {
    const { fit } = state.measurement || {}

    return fit
  },
  size(state) {
    const { size } = state.measurement || {}

    return Math.max(36, Number(size)) || 0
  },
  email: (state) => state.email,
  firstName: (state) => state.firstName,
  fitPreference: (state) => (state.measurement || {}).fitPreference,
  lengthPreference: (state) => (state.measurement || {}).lengthPreference,
}

export const mutations: MutationTree<UserModuleState> = {
  SET_USERDATA(state, userData) {
    const { email, firstName, lastName, measurement } = userData || {}

    Object.assign(state, {
      email: email || state.email,
      firstName: firstName || state.firstName,
      lastName: lastName || state.lastName,
      measurement,
    })
  },
  CLEAR_STATE(state) {
    Object.assign(state, getInitialState())
  },
}

export const actions: ActionTree<UserModuleState, RootState> = {
  fetchUser({ rootState }) {
    console.log('accessing rootState:', rootState)
  },
}
