import { useStore, computed } from '@nuxtjs/composition-api'

export type IMenuEntry = IMenuLink | IMenuNode
export interface IMenuLink {
  title: string
  to: any
}
export interface IMenuNode {
  title?: string
  parent: IMenuNode | null
  items: IMenuEntry[]
}

function getMenu(isUser: boolean) {
  if (isUser) {
    return {
      main: {
        parent: null,
        items: [
          {
            title: 'My Personal Shop',
            to: '/customer/shop',
          },
          {
            title: 'Account Details',
            to: '/customer/account',
          },
          {
            title: 'My Orders',
            to: '/customer/orders',
          },
          {
            title: 'Our Virtual Fitting',
            to: '/fitting',
          },
          {
            title: 'Our Fabrics',
            to: '/fabrics',
          },
          {
            title: 'Our Production',
            to: '/production',
          },
          {
            title: 'Gift Cards',
            to: '/products/gift-card',
          },
        ],
      },
      sub: {
        parent: null,
        items: [
          {
            title: 'Customer Service',
            to: '/customer-service',
          },
          {
            title: 'Contact Us',
            to: '/customer-service/contact-us',
          },
        ],
      },
    }
  } else {
    return {
      main: {
        parent: null,
        items: [
          {
            title: 'Home',
            to: '/',
          },
          {
            title: 'Our Virtual Fitting',
            to: '/fitting',
          },
          {
            title: 'Our Fabrics',
            to: '/fabrics',
          },
          {
            title: 'Our Production',
            to: '/production',
          },
          {
            title: 'Our T-shirts',
            to: '/collection',
          },
          {
            title: 'Gift Cards',
            to: '/products/gift-card',
          },
        ],
      },
      sub: {
        parent: null,
        items: [
          {
            title: 'Customer Service',
            to: '/customer-service',
          },
          {
            title: 'Contact Us',
            to: '/customer-service/contact-us',
          },
        ],
      },
    }
  }
}

export function useNavBar() {
  const store = useStore()

  const isFitted = computed(() => store.getters['user/isFitted'])
  const main = computed(() => getMenu(isFitted.value).main)
  const sub = computed(() => getMenu(isFitted.value).sub)

  return {
    main,
    sub,
  }
}
