import { ApolloClient, InMemoryCache } from '@apollo/client/core'
// import fetch from 'cross-fetch';

import {
  MUTATION_CHECKOUT_ATTRIBUTES_UPDATE,
  MUTATION_CHECKOUT_CREATE,
  MUTATION_CHECKOUT_LINE_ITEMS_UPDATE,
  MUTATION_CHECKOUT_LINE_ITEMS_REMOVE,
  MUTATION_CHECKOUT_LINE_ITEMS_ADD,
} from '~/services/shopify/shopify-storefront-queries'
import {
  ShopifyCheckoutAttributesUpdateV2Input,
  ShopifyCheckoutCreateInput,
  ShopifyCheckoutLineItemUpdateInput,
  ShopifyCheckoutLineItemInput,
} from '~/services/shopify/shopify-types'

const gqlURL = `https://${process.env.SHOPIFY_DOMAIN}${process.env.SHOPIFY_STOREFRONT_API_PATH}`
const token = process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN

global.fetch = require('cross-fetch')

const apolloClient = new ApolloClient({
  uri: gqlURL,
  headers: {
    'Content-Type': 'application/json',
    'X-Shopify-Storefront-Access-Token': token as string,
  },
  cache: new InMemoryCache(),
})

export async function checkoutUpdateAttributes(checkoutId: string, input: ShopifyCheckoutAttributesUpdateV2Input) {
  const queryResult = await apolloClient.mutate({
    mutation: MUTATION_CHECKOUT_ATTRIBUTES_UPDATE,
    variables: {
      checkoutId,
      input,
    },
  })

  return queryResult.data.checkoutAttributesUpdateV2.checkout as any
}

export async function checkoutCreate(input: ShopifyCheckoutCreateInput = {}) {
  const queryResult = await apolloClient.mutate({
    mutation: MUTATION_CHECKOUT_CREATE,
    variables: {
      input,
    },
  })

  return queryResult.data.checkoutCreate.checkout as any
}

export async function checkoutUpdateLineItems(
  checkoutId: string,
  lineItems: ShopifyCheckoutLineItemUpdateInput[] = [],
) {
  const queryResult = await apolloClient.mutate({
    mutation: MUTATION_CHECKOUT_LINE_ITEMS_UPDATE,
    variables: {
      checkoutId,
      lineItems,
    },
  })

  return queryResult.data.checkoutLineItemsUpdate.checkout as any
}

export async function checkoutAddLineItems(checkoutId: string, lineItems: ShopifyCheckoutLineItemInput[] = []) {
  const queryResult = await apolloClient.mutate({
    mutation: MUTATION_CHECKOUT_LINE_ITEMS_ADD,
    variables: {
      checkoutId,
      lineItems,
    },
  })
  return queryResult.data.checkoutLineItemsAdd.checkout as any
}

export async function checkoutRemoveLineItems(checkoutId: string, lineItemIds: string[] = []) {
  const queryResult = await apolloClient.mutate({
    mutation: MUTATION_CHECKOUT_LINE_ITEMS_REMOVE,
    variables: {
      checkoutId,
      lineItemIds,
    },
  })
  return queryResult.data.checkoutLineItemsRemove.checkout as any
}
