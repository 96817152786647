import { GetterTree, ActionTree, MutationTree } from 'vuex'
import _merge from 'lodash/merge'
import { RootState } from '~/store'

function getInitialState() {
  return {
    mode: 'default',
    nav: true,
    navColors: {
      default: { textColor: 'black', bgColor: 'transparent' },
      scroll: { textColor: 'black', bgColor: 'white' },
    } as any,
    footer: true,
  }
}

export const state = () => getInitialState()

export type PageModuleState = ReturnType<typeof state>

export const getters: GetterTree<PageModuleState, RootState> = {
  getColors(state) {
    return state.navColors[state.mode]
  },
  getPageSettings(state) {
    return { ...state }
  },
  getMode(state) {
    return state.mode
  },
}

export const mutations: MutationTree<PageModuleState> = {
  SET_MODE(state, mode = 'default') {
    state.mode = mode
  },
  INIT_PAGE(state, payload) {
    Object.assign(state, _merge(getInitialState(), payload))
  },
  CLEAR_STATE(state) {
    Object.assign(state, getInitialState())
  },
}

export const actions: ActionTree<PageModuleState, RootState> = {}
