import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store'
import { ICartItem, IProductVariant } from '~/services/api/db-types-shop'

function getInitialState() {
  return {
    checkout: {} as any,
    cartMessage: '',
  }
}

export const state = () => getInitialState()

export type CartModuleState = ReturnType<typeof state>

export const getters: GetterTree<CartModuleState, RootState> = {
  checkoutId: (state) => state.checkout.id,
  cartMessage: (state) => state.cartMessage,
  getTotalQuantity: (state) =>
    state.checkout.lineItems?.edges?.reduce((result: any, item: any) => result + item.node.quantity, 0),
  edges: (state) => (state.checkout.lineItems && state.checkout.lineItems.edges) || [],
  getItems: (state, getters) => {
    const items = getters.edges
      .map(({ node }: any) => {
        const { id, quantity, title, variant, customAttributes } = node
        return {
          id,
          title: title === 'Gift Card' ? title : title.split('|').slice(1).join('|') + ' Cotton',
          quantity,
          variant,
          customAttributes,
          image: variant.image,
        } as ICartItem
      })
      .filter((p: ICartItem) => p)

    return items
  },
}

export const mutations: MutationTree<CartModuleState> = {
  SET_CHECKOUT(state, checkoutData) {
    // console.log('SET_CHECKOUT', checkoutData)
    state.checkout = checkoutData
  },
  CLEAR_STATE(state) {
    Object.assign(state, getInitialState())
  },
  SHOW_MESSAGE(state, message) {
    Object.assign(state, { cartMessage: message })
  },
}

export const actions: ActionTree<CartModuleState, RootState> = {}
