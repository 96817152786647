import { useContext } from '@nuxtjs/composition-api'

import { isApolloError } from '@apollo/client/core'
interface DisplayError {
  err: any
  notify?: boolean
  text?: string
}

export function useMessage() {
  const { $toast, $sentry } = useContext() as any

  function showError(payload: DisplayError) {
    const { err, notify, text } = payload

    if ($toast) {
      $toast.clear()
      $toast.show(text || err.message || 'Oops, something went wrong', {
        type: 'error',
        position: 'top-center',
        duration: 3000,
        keepOnHover: true,
        // theme: 'outline',
        theme: 'bubble',
      })
    }

    if (notify) {
      notifyError(err)
    }
  }

  function showMessage(msg: string) {
    if ($toast) {
      $toast.clear()
      $toast.show(msg, {
        type: 'info',
        position: 'top-center',
        duration: 3000,
        keepOnHover: true,
        // theme: 'outline',
        theme: 'bubble',
      })
    }
  }

  function notifyError(err: any) {
    console.log('isApolloError:', isApolloError(err), err.message)
    $sentry && $sentry.captureException(err)
  }

  return {
    showError,
    showMessage,
    notifyError,
  }
}
