import { IMAGE_SIZES, IProductOptionName, PRODUCT_OPTIONS_MAP } from '../constants'
import { IProduct, IProductOptionSelectedHash, IProductVariant } from '../services/api/db-types-shop'

/**
 * Returns the variant of a product corresponding to the options given.
 *
 * @example
 * const selectedVariant = client.product.helpers.variantForOptions(product, {
 *   size: "Small",
 *   color: "Red"
 * });
 *
 * @memberof ProductHelpers
 * @method variantForOptions
 * @param {GraphModel} product The product to find the variant on. Must include `variants`.
 * @param {Object} options An object containing the options for the variant.
 * @return {GraphModel} The variant corresponding to the options given.
 */
export function variantForOptions(product: IProduct, options: Partial<IProductOptionSelectedHash>) {
  const tempOptions = Object.assign({}, options)
  return product.variants.find(function (variant) {
    return variant.selectedOptions.every(function (selectedOption) {
      return tempOptions[selectedOption.name as IProductOptionName] === selectedOption.value.valueOf()
    })
  })
}

/**
 * Generates the image src for a resized image with maximum dimensions `maxWidth` and `maxHeight`.
 * Images do not scale up.
 *
 * @example
 * const url = client.image.helpers.imageForSize(product.variants[0].image, {maxWidth: 50, maxHeight: 50});
 *
 * @memberof ImageHelpers
 * @method imageForSize
 * @param {String} src The original image src.
 * @param {Object} options An options object containing:
 *  @param {Integer} options.maxWidth The maximum width for the image.
 * @return {String} The image src for the resized image.
 */
export function imageForSize(src: string, _ref: { maxWidth: number }) {
  const maxWidth = _ref.maxWidth

  const splitUrl = src.split('?')
  const notQuery = splitUrl[0]
  const query = splitUrl[1] ? '?' + splitUrl[1] : ''

  // Use the section before the query
  const imageTokens = notQuery.split('.')

  // Take the token before the file extension and append the dimensions
  const imagePathIndex = imageTokens.length - 2

  imageTokens[imagePathIndex] = imageTokens[imagePathIndex] + '_' + maxWidth + 'x'

  return '' + imageTokens.join('.') + query
}

// const cdnPath = 'https://storage.proportionclothing.com'
const cdnPath = 'https://storage.googleapis.com/storage.proportionclothing.com'

// function canUseWebP() {
//   const elem = document.createElement('canvas')
//   if (!!(elem.getContext && elem.getContext('2d'))) {
//     return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
//   }
//   return false
// }

// const allowWebP = typeof window !== 'undefined' && canUseWebP()

export function getImagePathCDN(src: string) {
  return cdnPath + src
}

export function imageForSizeCdn(src: string, _ref: { maxWidth: number; format?: string }) {
  const maxWidth = _ref.maxWidth

  const splitUrl = src.split('.')
  const fileName = splitUrl[0]
  const extension = _ref.format || splitUrl[1]

  return cdnPath + fileName + '_' + maxWidth + 'x.' + extension
}

export function getVariantSelectedOptionHash(variant: IProductVariant) {
  const variantOptions = variant.selectedOptions.reduce((result, option) => {
    result[option.name] = option.value
    return result
  }, {} as IProductOptionSelectedHash)
  return variantOptions
}
export function firstVariantForOptions(
  variants: IProductVariant[],
  searchParams = {} as Partial<IProductOptionSelectedHash>,
) {
  const searchEntries = Object.entries(searchParams).filter(([_name, value]) => value != null)
  if (!searchEntries.length) {
    return undefined
  }
  return variants.find((v) => {
    return searchEntries.every(([name, value]) => {
      return v.selectedOptions.find(
        (option) =>
          option.name.toLowerCase() === name.toLowerCase() && option.value.toString() === (value || '').toString(),
      )
    })
  })
}
const defaultSize = '450'
export function getSrc(src: string | undefined) {
  if (src) {
    const resolver = getImageResolver(src)
    return resolver(src, {
      maxWidth: defaultSize,
    })
  }
  return ''
}

export function getSrcSet(src: string, format?: string) {
  if (src) {
    return getSrcArray(src, format)
      .map((imgSrc, index) => `${imgSrc} ${IMAGE_SIZES[index]}w`)
      .join(', ')
  }
  return ''
}

export function getSrcArray(src: string, format?: string) {
  const resolver = getImageResolver(src)
  return IMAGE_SIZES.map((size) =>
    resolver(src, {
      maxWidth: size,
      format,
    }),
  )
}

function getImageResolver(src: string): Function {
  if (!src) {
    return () => ''
  }
  if (src.includes('cdn.shopify.com')) {
    return imageForSize
  } else if (src.startsWith('/img')) {
    return imageForSizeCdn
  } else {
    return () => src
  }
}

export function getOptionData(name: IProductOptionName, value: string) {
  return PRODUCT_OPTIONS_MAP[name] && PRODUCT_OPTIONS_MAP[name][value]
}
