import Vue from 'vue'
import VueGtag from 'vue-gtag'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin((context, inject) => {
  const { app, env } = context

  const ENABLED_ANALYTICS = process.env.NODE_ENV === 'production' && process.env.GOOGLE_ANALYTICS_ID

  console.log({ ENABLED_ANALYTICS })

  Vue.use(
    VueGtag,
    {
      // https://matteo-gabriele.gitbook.io/vue-gtag/plugin-options
      config: {
        id: env.GOOGLE_ANALYTICS_ID,
        send_page_view: false,
      },
      deferScriptLoad: true,
      enabled: ENABLED_ANALYTICS,
      appName: `Proportion ${env.ENVIRONMENT}`,
      onReady(e) {
        console.log('VueGtag is ready')
      },
    },
    app.router,
  )

  inject('gtag', Vue.$gtag)
})
