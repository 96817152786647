import { computed, useContext, useStore } from '@nuxtjs/composition-api'
import { useMessage } from '~/use/useMessage'

import { IProductVariant, ICartItem, IProductOptionSelected } from '~/services/api/db-types-shop'
import { checkoutAddLineItems, checkoutCreate, checkoutUpdateAttributes } from '~/services/shopify/shopify-client'

export function useCart() {
  const { $gtag, $fb } = useContext() as any
  const { notifyError } = useMessage()

  const store = useStore() as any
  const checkoutWebUrl = computed(() => store.state.cart.checkout.webUrl)
  const checkoutId = computed(() => store.getters['cart/checkoutId'])
  const cartItems = computed<ICartItem[]>(() => store.getters['cart/getItems'])
  const totalQuantity = computed<number>(() => store.getters['cart/getTotalQuantity'])

  async function _tryCreateCheckout() {
    try {
      const newCheckout = await checkoutCreate()
      return newCheckout
    } catch (err) {
      notifyError(err)
    }
  }

  async function getCheckout() {
    let checkout

    if (checkoutId.value) {
      try {
        checkout = await checkoutUpdateAttributes(checkoutId.value, {
          customAttributes: [{ key: 'testing', value: 'checkout' }],
        })
      } catch (err) {
        checkout = await _tryCreateCheckout()
      }
    } else {
      checkout = await _tryCreateCheckout()
    }

    if (!checkout) {
      notifyError(new Error('checkout initialization failed'))
    } else {
      store.commit('cart/SET_CHECKOUT', checkout as any)
    }
  }

  function track(lineItems: any[], totalPrice?: number) {
    $gtag.event('add_to_cart', {
      items: lineItems.map((v: any) => ({
        item_id: v.variantId,
        quantity: v.quantity,
      })),
    })
    $fb.track('AddToCart', {
      content_type: 'product',
      contents: lineItems.map((v: any) => ({
        id: v.variantId,
        quantity: v.quantity,
      })),
      currency: 'USD',
      value: totalPrice,
    })
  }

  const cartAdd = async (variant?: IProductVariant) => {
    if (variant) {
      try {
        await getCheckout()

        const lineItems = [
          {
            customAttributes: variant.selectedOptions.map((option: IProductOptionSelected) => ({
              key: option.name,
              value: option.value,
            })),
            quantity: 1,
            variantId: variant.id as string,
          },
        ]
        const updatedCheckout = await checkoutAddLineItems(checkoutId.value, lineItems)

        store.commit('cart/SET_CHECKOUT', updatedCheckout as any)

        track(lineItems, Number(variant.price))
      } catch (err) {
        notifyError(err)
      }
    }
  }

  async function cartAddMany(lineItems: any[], totalPrice?: number) {
    try {
      await getCheckout()

      const updatedCheckout = await checkoutAddLineItems(checkoutId.value, lineItems)

      store.commit('cart/SET_CHECKOUT', updatedCheckout as any)

      track(lineItems, totalPrice)
    } catch (err) {
      notifyError(err)
    }
  }

  function goToCheckout() {
    try {
      $gtag.event('begin_checkout', {
        items: cartItems.value.map((v: any) => ({
          item_id: v.id,
          item_name: v.title,
          quantity: v.quantity,
          variant: v.variant,
        })),
      })
      $fb.track('InitiateCheckout', {
        num_items: cartItems.value.length,
        content_type: 'product',
        contents: cartItems.value.map((v: any) => ({
          id: v.id,
          item_name: v.title,
          quantity: v.quantity,
          variant: v.variant,
        })),
      })

      window.location.href = checkoutWebUrl.value
    } catch (err) {
      notifyError(err)
    }
  }

  return {
    totalQuantity,
    cartAdd,
    cartAddMany,
    getCheckout,
    goToCheckout,
  }
}
