import { gql } from '@apollo/client/core'

export const FRAGMENT_USER_ERROR = gql`
  fragment UserErrorFragment on UserError {
    field
    message
  }
`

export const FRAGMENT_CHECKOUT_USER_ERROR = gql`
  fragment CheckoutUserErrorFragment on CheckoutUserError {
    field
    message
    code
  }
`

export const FRAGMENT_VARIANT = gql`
  fragment VariantFragment on ProductVariant {
    id
    title
    price
    priceV2 {
      amount
      currencyCode
    }
    presentmentPrices(first: 20) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          price {
            amount
            currencyCode
          }
          compareAtPrice {
            amount
            currencyCode
          }
        }
      }
    }
    weight
    available: availableForSale
    sku
    compareAtPrice
    compareAtPriceV2 {
      amount
      currencyCode
    }
    image {
      id
      src: originalSrc
      altText
    }
    selectedOptions {
      name
      value
    }
    unitPrice {
      amount
      currencyCode
    }
    unitPriceMeasurement {
      measuredType
      quantityUnit
      quantityValue
      referenceUnit
      referenceValue
    }
  }
`

export const FRAGMENT_PRODUCT = gql`
  fragment ProductFragment on Product {
    id
    availableForSale
    createdAt
    updatedAt
    descriptionHtml
    description
    handle
    productType
    title
    vendor
    publishedAt
    onlineStoreUrl
    options {
      name
      values
    }
    images(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          src
          altText
        }
      }
    }
    variants(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...VariantFragment
        }
      }
    }
  }
  ${FRAGMENT_VARIANT}
`

export const FRAGMENT_VARIANT_WITH_PRODUCT = gql`
  fragment VariantWithProductFragment on ProductVariant {
    ...VariantFragment
    product {
      id
      handle
    }
  }
  ${FRAGMENT_VARIANT}
`

export const FRAGMENT_APPLIED_GIFT_CARD = gql`
  fragment AppliedGiftCardFragment on AppliedGiftCard {
    amountUsedV2 {
      amount
      currencyCode
    }
    balanceV2 {
      amount
      currencyCode
    }
    presentmentAmountUsed {
      amount
      currencyCode
    }
    id
    lastCharacters
  }
`

export const FRAGMENT_DISCOUNT_APPLICATION = gql`
  fragment DiscountApplicationFragment on DiscountApplication {
    targetSelection
    allocationMethod
    targetType
    value {
      ... on MoneyV2 {
        amount
        currencyCode
      }
      ... on PricingPercentageValue {
        percentage
      }
    }
    ... on ManualDiscountApplication {
      title
      description
    }
    ... on DiscountCodeApplication {
      code
      applicable
    }
    ... on AutomaticDiscountApplication {
      title
    }
  }
`

export const FRAGMENT_MAILING_ADDRESS = gql`
  fragment MailingAddressFragment on MailingAddress {
    id
    address1
    address2
    city
    company
    country
    firstName
    formatted
    lastName
    latitude
    longitude
    phone
    province
    zip
    name
    countryCode: countryCodeV2
    provinceCode
  }
`

export const FRAGMENT_CHECKOUT = gql`
  fragment CheckoutFragment on Checkout {
    id
    ready
    requiresShipping
    note
    paymentDue
    paymentDueV2 {
      amount
      currencyCode
    }
    webUrl
    orderStatusUrl
    taxExempt
    taxesIncluded
    currencyCode
    totalTax
    totalTaxV2 {
      amount
      currencyCode
    }
    lineItemsSubtotalPrice {
      amount
      currencyCode
    }
    subtotalPrice
    subtotalPriceV2 {
      amount
      currencyCode
    }
    totalPrice
    totalPriceV2 {
      amount
      currencyCode
    }
    completedAt
    createdAt
    updatedAt
    email
    discountApplications(first: 10) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...DiscountApplicationFragment
        }
      }
    }
    appliedGiftCards {
      ...AppliedGiftCardFragment
    }
    shippingAddress {
      ...MailingAddressFragment
    }
    shippingLine {
      handle
      price
      priceV2 {
        amount
        currencyCode
      }
      title
    }
    customAttributes {
      key
      value
    }
    order {
      id
      processedAt
      orderNumber
      subtotalPrice
      subtotalPriceV2 {
        amount
        currencyCode
      }
      totalShippingPrice
      totalShippingPriceV2 {
        amount
        currencyCode
      }
      totalTax
      totalTaxV2 {
        amount
        currencyCode
      }
      totalPrice
      totalPriceV2 {
        amount
        currencyCode
      }
      currencyCode
      totalRefunded
      totalRefundedV2 {
        amount
        currencyCode
      }
      customerUrl
      shippingAddress {
        ...MailingAddressFragment
      }
      lineItems(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            title
            variant {
              ...VariantWithProductFragment
            }
            quantity
            customAttributes {
              key
              value
            }
          }
        }
      }
    }
    lineItems(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          title
          variant {
            ...VariantWithProductFragment
          }
          quantity
          customAttributes {
            key
            value
          }
          discountAllocations {
            allocatedAmount {
              amount
              currencyCode
            }
            discountApplication {
              ...DiscountApplicationFragment
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_VARIANT_WITH_PRODUCT}
  ${FRAGMENT_MAILING_ADDRESS}
  ${FRAGMENT_APPLIED_GIFT_CARD}
  ${FRAGMENT_DISCOUNT_APPLICATION}
`

export const MUTATION_CHECKOUT_ATTRIBUTES_UPDATE = gql`
  mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${FRAGMENT_CHECKOUT}
  ${FRAGMENT_CHECKOUT_USER_ERROR}
  ${FRAGMENT_USER_ERROR}
`

export const MUTATION_CHECKOUT_CREATE = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${FRAGMENT_CHECKOUT}
  ${FRAGMENT_CHECKOUT_USER_ERROR}
  ${FRAGMENT_USER_ERROR}
`

export const MUTATION_CHECKOUT_LINE_ITEMS_UPDATE = gql`
  mutation checkoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${FRAGMENT_CHECKOUT}
  ${FRAGMENT_CHECKOUT_USER_ERROR}
  ${FRAGMENT_USER_ERROR}
`

export const MUTATION_CHECKOUT_LINE_ITEMS_REMOVE = gql`
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${FRAGMENT_CHECKOUT}
  ${FRAGMENT_CHECKOUT_USER_ERROR}
  ${FRAGMENT_USER_ERROR}
`

export const MUTATION_CHECKOUT_LINE_ITEMS_ADD = gql`
  mutation checkoutLineItemsAdd($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        ...UserErrorFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${FRAGMENT_CHECKOUT}
  ${FRAGMENT_CHECKOUT_USER_ERROR}
  ${FRAGMENT_USER_ERROR}
`
