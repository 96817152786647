import { IProductOptionName, IProductOptionValue, IProductParameterType, PRODUCT_INFO_METAFIELDS } from '~/constants'

// import { RequireAll } from '~/services/type-helpers'

export interface IKeyValueRecord {
  key: string
  value: string
}

export interface IImage {
  src: string
  altText?: string | null
}

// export type ISeo = Pick<ShopifyAdminSeo, 'title' | 'description'>

export interface IMetaData {
  title?: string
  description?: string
  image?: string
  url?: string
}
export interface ICollection {
  type?: string
  id: string
  handle: string
  title: string
  collectionNumber: string
  description: string
  descriptionHtml: string
  updatedAt?: string
  seo: any
  image: IImage
  products: IProduct[]
}
export interface IProductOptionSelected {
  name: IProductOptionName
  value: IProductOptionValue
}

export type IProductOptionSelectedHash = Record<IProductOptionName, IProductOptionValue | null>

export interface IProductVariant {
  id: string
  available: boolean
  price: string
  // id: NonNullable<ProductVariant['id']>
  // title: NonNullable<ProductVariant['title']>
  // price: NonNullable<ProductVariant['price']>
  // weight: NonNullable<ProductVariant['weight']>
  // available: NonNullable<ProductVariant['available']>
  // sku: NonNullable<ProductVariant['sku']>
  image: IImage
  selectedOptions: IProductOptionSelected[]
}

export type IProductOptionMapItem = {
  value: string
  optionData?: any
  available?: boolean
  price?: string
  image?: IImage
}

export type IProductOptionMaps = Record<
  IProductOptionName,
  {
    values: string[]
    items: (IProductOptionMapItem | undefined)[]
  }
>

export interface IProduct {
  type: string
  id: string
  title: string
  description: string
  handle: string
  // options: Option[]
  productType: string
  vendor: string
  variants: IProductVariant[]
  images: IImage[]
  optionMaps: IProductOptionMaps
  tags: string[]
  params: Record<IProductParameterType, string>
  collectionNumber: string
  metafields: Partial<Record<typeof PRODUCT_INFO_METAFIELDS[number], string>>
  seo: any
}

export interface IMarketingAd {
  type: string
  id: string
  title?: string
  description: string[]
  images?: any[]
  button?: {
    text: string
    to: string
  }
}

export type ICollectionGridItem = IProduct | IMarketingAd

// export type ICartItem = RequireAll<
//   Pick<LineItem, 'id' | 'title' | 'quantity'> & {
//     variant: ProductVariant
//     image?: IImage
//     // customAttributeStrings: string[]
//     customAttributes: object[]
//   }
// >
export type ICartItem = any
