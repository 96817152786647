import { ApolloClient, InMemoryCache, gql } from '@apollo/client/core'

import {
  AuthResponse,
  User,
  UpdateUserInput,
  UserNotification,
  UserNotificationInput,
  RegisterInput,
  Measurement,
  RegisterMeasurementInput,
  MeasurementPhotosInput,
  MagicLinkInput,
  Order,
} from './db'
import { getGraphQLErrorMessage } from '~/utils/graphQLErrors'

const gqlURL = `${process.env.BASE_URL}${process.env.API_PATH}`

const apolloClient = new ApolloClient({
  uri: gqlURL,
  headers: {},
  cache: new InMemoryCache(),
})

const MEASUREMENT_FRAGMENT = gql`
  fragment MEASUREMENT_FRAGMENT on Measurement {
    measurementData(unitSystem: "imperial")
    fitPreference
    lengthPreference
    fit
    size
  }
`

const USER_FRAGMENT = gql`
  fragment USER_FRAGMENT on User {
    email
    firstName
    lastName
    phone
    measurement {
      ...MEASUREMENT_FRAGMENT
    }
  }
  ${MEASUREMENT_FRAGMENT}
`

export async function magicLink(data: MagicLinkInput) {
  const MUTATION_MAGIC_LINK = gql`
    mutation ($data: MagicLinkInput!) {
      magicLink(data: $data)
    }
  `
  const queryResult = await apolloClient.mutate({
    mutation: MUTATION_MAGIC_LINK,
    variables: { data },
  })

  // if (queryResult.errors) {
  //   throw new Error(getGraphQLErrorMessage(queryResult?.errors))
  // }
}

export async function register(data: RegisterInput) {
  const MUTATION_REGISTER = gql`
    mutation register($data: RegisterInput!) {
      register(data: $data) {
        user {
          ...USER_FRAGMENT
        }
        token
      }
    }
    ${USER_FRAGMENT}
  `
  const { register } = (
    await apolloClient.mutate({
      mutation: MUTATION_REGISTER,
      variables: { data },
    })
  ).data

  return register as AuthResponse
}

export async function me(token: string) {
  const QUERY_ME = gql`
    query {
      me {
        ...USER_FRAGMENT
      }
    }
    ${USER_FRAGMENT}
  `
  const { me } = (
    await apolloClient.query({
      query: QUERY_ME,
      context: {
        headers: {
          authorization: `bearer ${token}`,
        },
      },
    })
  ).data

  return me as User
}

export async function updateMe(data: UpdateUserInput, token: string) {
  const MUTATION_UPDATE_USER = gql`
    mutation updateMe($data: UpdateUserInput!) {
      updateMe(data: $data) {
        ...USER_FRAGMENT
      }
    }
    ${USER_FRAGMENT}
  `
  const { updateMe } = (
    await apolloClient.mutate({
      mutation: MUTATION_UPDATE_USER,
      variables: { data },
      context: {
        headers: {
          authorization: `bearer ${token}`,
        },
      },
    })
  ).data

  return updateMe as User
}

export async function registerMeasurement(
  data: RegisterMeasurementInput,
  token: string,
  photos: MeasurementPhotosInput,
) {
  const MUTATION_REGISTER_MEASUREMENT = gql`
    mutation registerMeasurement($data: RegisterMeasurementInput!, $photos: MeasurementPhotosInput!) {
      registerMeasurement(data: $data, photos: $photos) {
        ...MEASUREMENT_FRAGMENT
      }
    }
    ${MEASUREMENT_FRAGMENT}
  `
  const queryResult = await apolloClient.mutate({
    mutation: MUTATION_REGISTER_MEASUREMENT,
    variables: { data, photos },
    context: {
      headers: {
        authorization: `bearer ${token}`,
      },
    },
  })

  if (queryResult.data?.registerMeasurement) {
    return queryResult.data?.registerMeasurement as Measurement
  } else {
    // throw new Error(getGraphQLErrorMessage(queryResult?.errors))
  }
}

export async function getOrders(token: string) {
  const QUERY_ORDERS = gql`
    query {
      me {
        orders {
          id
          orderNumber
          email
          createdAt
          updatedAt
          closedAt
          cancelledAt
          amount
          status
          fulfillmentStatus
          paymentStatus
          shopifyOrder
        }
      }
    }
  `
  const { me } = (
    await apolloClient.query({
      query: QUERY_ORDERS,
      context: {
        headers: {
          authorization: `bearer ${token}`,
        },
      },
    })
  ).data

  return me.orders as Order[]
}

export async function createUserNotification(data: UserNotificationInput, token: string) {
  const MUTATION_CREATE_NOTIFICATION = gql`
    mutation createUserNotification($data: UserNotificationInput!) {
      createUserNotification(data: $data) {
        id
        userId
        type
        scheduledAt
      }
    }
  `
  const { createUserNotification } = (
    await apolloClient.mutate({
      mutation: MUTATION_CREATE_NOTIFICATION,
      variables: { data },
      context: {
        headers: {
          authorization: `bearer ${token}`,
        },
      },
    })
  ).data

  return createUserNotification as UserNotification
}
