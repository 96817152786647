import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'

export default ({ store }) => {
  const allowedMutations = ['SET_TOKEN', 'SET_STATE_PERSIST', 'CLEAR_STATE', 'cart/SET_CHECKOUT', 'cart/CLEAR_STATE']
  createPersistedState({
    key: 'proportionclothing',
    fetchBeforeUse: true,
    filter: (mutation) => {
      return allowedMutations.includes(mutation.type)
    },
    reducer: (state) => {
      return {
        token: state.token,
        cookieAccepted: state.cookieAccepted,
        welcomeScreen: state.welcomeScreen,
        cart: {
          checkout: {
            id: state.cart.checkout.id,
          },
        },
      }
    },
    storage: {
      getItem: (key) => Cookies.get(key),
      setItem: (key, value) => Cookies.set(key, value, { expires: 7, secure: false }),
      removeItem: (key) => Cookies.remove(key),
    },
  })(store)
}
