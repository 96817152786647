import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin(({ $passwordProtect, route, app, redirect }, inject) => {
  $passwordProtect.registerRedirectCallback(({ options }) => {
    if (!options.enabled || route.path === options.formPath) {
      return
    }

    redirect(options.formPath, { previousPath: route.fullPath })
  })
  inject('passwordProtect', $passwordProtect)
})
