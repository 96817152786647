import {
  mdiPlus,
  mdiMinus,
  mdiClose,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiCheckBold,
  mdiCamera,
  mdiAccount,
} from '@mdi/js'

const ICONS = {
  close: mdiClose,

  prev: mdiChevronLeft,
  next: mdiChevronRight,
  up: mdiChevronUp,

  check: mdiCheckBold,
  empty: '',

  plusAlt: mdiPlus,

  minusAlt: mdiMinus,

  camera: mdiCamera,
  account: mdiAccount,
}

const defaultIconSize = '24'

const PIcon = ({ props, listeners, scopedSlots, slots, data, children, ...other }: any) => {
  let iconName: keyof typeof ICONS = 'empty'

  if (slots().default) {
    iconName = slots().default[0].text!.trim() as any
  }

  let { size } = props
  size = size || defaultIconSize

  return (
    <svg class="fill-current icon" width={size} height={size} viewBox={`0 0 24 24`}>
      <path d={ICONS[iconName]} />
    </svg>
  )
}

export default PIcon
