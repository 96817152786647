import { GetterTree, ActionTree, MutationTree } from 'vuex'

function getInitialState() {
  return {
    token: '',
    cookieAccepted: false,
    welcomeScreen: false,
    overlay: '',
  }
}

export const state = () => getInitialState()

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  token: (state) => state.token,
  cookieAccepted: state => state.cookieAccepted,
  welcomeScreen: state => state.welcomeScreen,
}

export const mutations: MutationTree<RootState> = {
  SET_TOKEN: (state, value: string) => (state.token = value),
  SET_STATE_PERSIST: (state, payload: any) => Object.assign(state, { ...payload }),
  CLEAR_STATE: (state) => Object.assign(state, getInitialState()),
  SET_OVERLAY: (state, value) => {
    state.overlay = value
  },
}

export const actions: ActionTree<RootState, RootState> = {}
