export interface IProductParam {
  readonly title: string
  readonly key: string
  readonly options: { value: string; title?: string }[]
}

export const PRODUCT_OPTION_NAMES = ['color', 'size'] as const
export const PRODUCT_SIZE_VALUES = ['36', '38', '40', '42', '44', '46'] as const
export const PRODUCT_COLOR_VALUES = [
  'Optic White',
  'Bone',
  'White Sand',
  'Desert Sage',
  'Arctic Ice',
  'House Black',
  'Heather Grey',
] as const
export type IProductOptionName = typeof PRODUCT_OPTION_NAMES[number]
export type IProductOptionColorValue = typeof PRODUCT_COLOR_VALUES[number]
export type IProductOptionSizeValue = typeof PRODUCT_SIZE_VALUES[number]
export type IProductOptionValue = IProductOptionColorValue | IProductOptionSizeValue

export const DEFAULT_PRODUCT_OPTIONS: Record<IProductOptionName, readonly IProductOptionValue[]> = {
  size: PRODUCT_SIZE_VALUES,
  color: PRODUCT_COLOR_VALUES,
}
export const PRODUCT_INFO_METAFIELDS = [
  'featured-color',
  'video',
  'fabric-image',
  'model-specs-description',
  'fabric-title',
  'fabric-description',
  'other-model-1-title',
  'other-model-1-image',
  'other-model-1-testimonial',
  'other-model-1-specs',
  'other-model-2-title',
  'other-model-2-image',
  'other-model-2-testimonial',
  'other-model-2-specs',
  'other-model-3-title',
  'other-model-3-image',
  'other-model-3-testimonial',
  'other-model-3-specs',
] as const
export const METAFIELDS = {
  'product-info': PRODUCT_INFO_METAFIELDS,
} as const

export const PRODUCT_COLORS: Record<IProductOptionColorValue, string> = {
  'Optic White': '#FFFFFF',
  Bone: '#F0EBDD',
  'White Sand': '#E1D4Cf',
  'Desert Sage': '#B8B8B8',
  'Arctic Ice': '#DBE6E1',
  'House Black': '#000000',
  'Heather Grey': '#99989E',
} as const

export type SIZE_RECORD = Record<'US', string>
const PRODUCT_SIZES: Record<IProductOptionSizeValue, SIZE_RECORD> = {
  36: {
    US: 'XS',
  },
  38: {
    US: 'S',
  },
  40: {
    US: 'M',
  },
  42: {
    US: 'L',
  },
  44: {
    US: 'XL',
  },
  46: {
    US: 'XXL',
  },
} as const
export const PRODUCT_OPTIONS_MAP: Record<IProductOptionName, any> = {
  color: PRODUCT_COLORS,
  size: PRODUCT_SIZES,
} as const

export const WEBSITE_TITLE = 'Proportion Clothing' as const
export const SHOP_SEO_TITLE = 'Proportion Clothing | Exclusively Men’s T-shirts. Everything but Basic.' as const

export const PRODUCT_PARAMETERS_TYPES = ['fit', 'style', 'sleeve', 'fabric'] as const
export type IProductParameterType = typeof PRODUCT_PARAMETERS_TYPES[number]

// options values must be kebab cased
export const PRODUCT_PARAMETERS = [
  {
    title: 'fit',
    optionTemplate: 'Fit ${value}',
    key: 'fit',
    options: [
      { value: '01', title: 'Fit.01' },
      { value: '02', title: 'Fit.02' },
      { value: '03', title: 'Fit.03' },
    ],
  },
  {
    title: 'style',
    optionTemplate: '${value}',
    key: 'style',
    options: [
      { value: 'crewneck', title: 'Crewneck' },
      { value: 'side-split', title: 'Side Split' },
    ],
  },
  {
    title: 'sleeve',
    optionTemplate: '${value} Sleeve',
    key: 'sleeve',
    options: [
      { value: 'short', title: 'Short' },
      { value: 'long', title: 'Long' },
    ],
  },
  {
    title: 'fabric',
    optionTemplate: '${value} Cotton',
    key: 'fabric',
    options: [
      { value: 'lightweight', title: 'Lightweight' },
      { value: 'medium-weight', title: 'Medium Weight' },
      { value: 'heavy-weight', title: 'Heavy Weight' },
    ],
  },
] as const

export const IMAGE_SIZES = ['100', '250', '500', '750', '1000', '1250', '1500'] as const
export type IImageSize = typeof IMAGE_SIZES[number]
// export const IMAGE_SIZES_MAP: Record<IImageSize, number> = {
//   thumbnail: 30,
//   sm: 300,
//   md: 600,
//   lg: 900,
// } as const
