export type AuthResponse = {
  user: User
  token: string
}

export type MagicLinkInput = {
  email: string
}

export type Measurement = {
  __typename?: any
  id: string
  createdAt?: any
  updatedAt?: any
  userId: string
  measurementData: any
  fitPreference: UserFitPreference
  lengthPreference: UserLengthPreference
  fit?: string // '01' | '02' | '03'
  size?: number // 34 | 36 | 38 | 40 | 42 | 44 | 46 | 48 | 50
}

export type RegisterMeasurementInput = {
  measurementData: object
  fitPreference?: UserFitPreference
  lengthPreference?: UserLengthPreference
}
export type MeasurementPhotosInput = {
  frontPhotoSrc: string
  sidePhotoSrc: string
  type: string
}
export enum OrderStatus {
  open,
  closed,
  cancelled,
}
enum OrderFulfillmentStatus {
  fulfilled,
  in_progress,
  on_hold,
  open,
  partially_fulfilled,
  pending_fulfillment,
  restocked,
  scheduled,
  unfulfilled,
}
enum OrderPaymentStatus {
  pending,
  authorized,
  partially_paid,
  partially_refunded,
  voided,
  paid,
  refunded,
  expired,
}
export type Order = {
  id: string
  orderNumber: number
  email: string
  createdAt: any
  updatedAt: any
  closedAt?: any
  cancelledAt?: any
  amount: number
  paymentStatus?: OrderPaymentStatus
  status: OrderStatus
  fulfillmentStatus: OrderFulfillmentStatus
  shopifyOrder: any
}

export type User = {
  id: string
  createdAt: any // generated date
  updatedAt: any // generated date
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
  shopifyData?: object
  fullName: string
  measurement: Measurement
  orders: Order[]
}

export type RegisterInput = {
  firstName: string
  lastName: string
  email: string
  phone?: string
}

export type UpdateUserInput = {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  shopifyData?: any
}

export enum UserFitPreference {
  MoreFitted = -2,
  Fitted = -1,
  Normal = 0,
  Loose = 1,
  LooserFit = 2,
}

export enum UserLengthPreference {
  'AboveWidestPointOfHips' = 1,
  'AtWidestPointOfHips' = 2,
  'BelowWidestPointOfHips' = 3,
}
export enum UserNotificationType {
  fitFlowSMSReminder = 'fitFlowSMSReminder',
}

export type UserNotification = {
  id: any
  createdAt: any
  updatedAt: any
  userId: string
  type: UserNotificationType
  scheduledAt: any
  sent: boolean
}

export type UserNotificationInput = {
  scheduledAt: any
  type: UserNotificationType
}
